


































































































import i18n, { changeLang } from '@/i18n'
import { ICertificate } from '@/shared/model/certificate.model'
import axios from 'axios'
import dayjs from 'dayjs'
import Vue from 'vue'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

export default Vue.extend({
  name: 'CertificatePdf',

  data () {
    return {
      certificate: undefined as ICertificate | undefined,
      processedTextBlock: '',
    }
  },
  computed: {},
  watch: {
    $route: function () {
      this.getCertificateData()
    },
  },
  created () {
    this.getCertificateData()
  },

  methods: {
    async getCertificateData () {
      const id = this.$route.params.id

      if (id) {
        await axios.get(`/api/certificate/${id}`).then((result) => {
          if (result.data) {
            this.certificate = result.data
            if (this.certificate && this.certificate.language) {
              i18n.locale = this.certificate.language
              changeLang(this.certificate.language)
            }
            this.processTextBlock()
          }
        })
      } else {
        this.certificate = undefined
      }
    },
    processTextBlock () {
      if (
        this.certificate &&
        this.certificate.certificateTemplateObject &&
        this.certificate.certificateTemplateObject.textBlock
      ) {
        this.processedTextBlock = this.certificate.certificateTemplateObject.textBlock
          .replace('{{datum}}', dayjs(this.certificate.date).format('DD.MM.YYYY'))
          .replace('{{ablaufdatum}}', dayjs(this.certificate.dueDate).format('DD.MM.YYYY'))
      }
    },
  },
})
